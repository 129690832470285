// Generated by Framer (ef2040c)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Text, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {MhKnVZEhS: {hover: true}};

const cycleOrder = ["MhKnVZEhS"];

const variantClassNames = {MhKnVZEhS: "framer-v-hnu1ip"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "MhKnVZEhS", title: BAvMjb5gC = "About", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "MhKnVZEhS", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-rvm5v", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-hnu1ip", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"MhKnVZEhS"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"MhKnVZEhS-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Text __fromCanvasComponent alignment={"left"} className={"framer-kxt4f6"} data-framer-name={"About"} fonts={["GF;Altone-700"]} layoutDependency={layoutDependency} layoutId={"LB0H8MGPO"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>About</span><br></span></span>"} style={{"--framer-font-family": "\"Altone\", serif", "--framer-font-size": "36px", "--framer-font-style": "normal", "--framer-font-weight": 700, "--framer-letter-spacing": "0px", "--framer-line-height": "24px", "--framer-text-alignment": "left", "--framer-text-color": "rgb(255, 255, 255)", "--framer-text-decoration": "none", "--framer-text-transform": "capitalize"}} text={BAvMjb5gC} transition={transition} variants={{"MhKnVZEhS-hover": {"--framer-font-family": "\"Altone Bold\", serif", "--framer-font-weight": 400, "--framer-text-color": "var(--token-e938dcc6-2a0e-4c12-9846-4efab72d34e8, rgb(228, 2, 70))"}}} verticalAlignment={"center"} withExternalLayout {...addPropertyOverrides({"MhKnVZEhS-hover": {fonts: ["CUSTOM;Altone Bold"]}}, baseVariant, gestureVariant)}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-rvm5v [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rvm5v * { box-sizing: border-box; }", ".framer-rvm5v .framer-qj1man { display: block; }", ".framer-rvm5v .framer-hnu1ip { height: 50px; overflow: hidden; position: relative; width: 108px; }", ".framer-rvm5v .framer-kxt4f6 { bottom: 0px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }", ".framer-rvm5v .framer-v-hnu1ip .framer-hnu1ip { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 108
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"G1OKHcoVD":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"BAvMjb5gC":"title"}
 */
const FramerSVJoMv_dA: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerSVJoMv_dA;

FramerSVJoMv_dA.displayName = "About tab";

FramerSVJoMv_dA.defaultProps = {height: 50, width: 108};

addPropertyControls(FramerSVJoMv_dA, {BAvMjb5gC: {defaultValue: "About", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerSVJoMv_dA, [{family: "Altone Bold", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/SVJoMv_dA:default", url: "assets/PpVpSN8600IvniFmaRfauEHiD1g.ttf"}, url: new URL("assets/PpVpSN8600IvniFmaRfauEHiD1g.ttf", import.meta.url).href}])